const SOCIAL_LINKS = [
  {
    icon: "fa-facebook-f",
    title: "Facebook",
    link: "https://www.facebook.com/GobSanNicolas"
  },
  {
    icon: "fa-twitter",
    title: "Twitter",
    link: "https://www.twitter.com/GobSanNicolas/"
  },
  {
    icon: "fa-instagram",
    title: "Instagram",
    link: "https://www.instagram.com/GobSanNicolas/"
  },
  {
    icon: "fa-youtube",
    title: "YouTube",
    link: "https://www.youtube.com/user/GobiernoSanNicolas/videos"
  },
];

export { SOCIAL_LINKS };
