<template>
  <div class="navbarmobile-comp">
    <button class="navbarmobile-btn-menu" @click="mth_Toggle">
      <i class="fa-solid fa-bars"></i>
    </button>

    <router-link
      class="logo-link"
      :to="LOGO_LINK.toView"
      :title="LOGO_LINK.title"
    >
      <img
        class="logo-img"
        :src="IMGS_GOB_SN.LogoSNFullLandscape"
        alt="GOB SN LOGO"
      />
    </router-link>

    <div class="navbarmobile-menu" v-if="IsActive">
      <div class="navbarmobile-bg" @click="mth_Toggle"></div>
      <div class="navbarmobile-items">
        <router-link class="navbarmobile-item title" :to="LOGO_LINK.toView" :title="LOGO_LINK.title">
          <img class="item-img" :src="IMGS_GOB_SN.LogoSN" alt="IMG">
          <p class="item-text">Portal de Transparencia</p>
        </router-link>
        
        <router-link 
          class="navbarmobile-item" 
          v-for="(item, index) in navbarLinks" 
          :key="index" 
          :to="item.toView" 
          :title="item.title"
        >
          <!-- <img class="item-img" :src="IMGS_GOB_SN.LogoSN" alt="IMG"> -->
          <i class="item-icon fa-solid" :class="`fa-${ item.icon }`"></i>
          <p class="item-text">{{ item.text }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { IMGS_GOB_SN } from "@/assets/js/imgs.js";

export default {
  name: "navbarmobile-comp",
  props: [],
  mounted() {
    this.IMGS_GOB_SN = IMGS_GOB_SN;
  },
  data() {
    return {
      IMGS_GOB_SN: {},
      LOGO_LINK: {
        toView: {
          name: "Inicio",
          hash: "",
        },
        text: "Home",
        title: "Inicio",
      },
      IsActive: false,
      navbarLinks:[
        {
          toView: {
            name: "Dependencias",
            hash: "",
          },
          text: "Dependencias",
          title: "Dependencias",
          icon: "newspaper",
        },
        {
          toView: {
            name: "Normativa",
            hash: "",
          },
          text: "Normativa",
          title: "Normativa",
          icon: "file-invoice",
        },
        {
          toView: {
            name: "Inicio",
            hash: "#Contacto",
          },
          text: "Contacto",
          title: "Contacto",
          icon: "address-book",
        }
      ]
    };
  },
  methods: {
    mth_Toggle() {
      this.IsActive = !this.IsActive;
    }
  },
  computed: {},
};
</script>

<style scoped>
.navbarmobile-comp {
  position: sticky;
  top: 0;
  background-color: #03437b;
  grid-template-columns: 0.4fr 1fr 0.4fr;
}

.navbarmobile-btn-menu {
  background-color: transparent;
  border: none;
  color: var(--white-00);
  font-size: 1.5rem;
  cursor: pointer;
}

.logo-link{
  text-decoration: none; 
  justify-self: center;
}

.logo-img {
  object-fit: contain;
  aspect-ratio: 198/74;
  width: 50vw;
  padding: 0.5rem;
}

.navbarmobile-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  grid-template-areas: "MENU";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.navbarmobile-bg {
  grid-area: MENU;
  background-color: var(--black-00-navbarmobile-bg);
}

.navbarmobile-items {
  grid-area: MENU;
  justify-self: start;
  background-color: var(--white-01);
  max-width: 80vw;
  padding: 0.5rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  align-content: start;
  filter: drop-shadow(0rem 0rem 0.5rem rgba(0, 0, 0, 0.5));
}

.navbarmobile-item {
  display: grid;
  gap: 1rem;
  padding: 1rem;
  grid-template-columns: auto 1fr;
  align-items: center;
  text-decoration: none;
  border-radius: 0.2rem;
}

.item-img {
  height: 2rem;
}

.item-icon {
  font-size: 1.5rem;
  width: 2rem;
  text-align: center;
  color: var(--gray-00);
}

.item-text {
  font-size: 0.8rem;
  word-break: break-word;
  color: var(--black-01);
}

.title .item-img {
  height: 3rem;
}

.title .item-text {
  font-size: 1rem;
  color: var(--azul-01);
}

.navbarmobile-item:hover {
  background-color: var(--black-00-navbarmobile-item-bg-hover);
}

.navbarmobile-item:hover .item-text {
  color: var(--azul-01);
}

.navbarmobile-item:hover .item-icon {
  color: var(--azul-01);
}
</style>
