export default {
  state: {
    isNavbarFullText: true,
  },
  mutations: {
    mut_Save_IsNavbar(state, params) {
      state.isNavbarFullText = params;
    },
  },
  actions: {
    act_Save_IsNavbar(context, params) {
      context.commit("mut_Save_IsNavbar", params);
    },
  },
  getters: {
    get_IsNavbar(state) {
      return state.isNavbarFullText;
    },
  },
};
