const NAVBAR_SECTIONS = [
  {
    toView: {
      name: "Dependencias",
      hash: "",
    },
    text: "Dependencias",
    title: "Dependencias",
  },
  {
    toView: {
      name: "Normativa",
      hash: "",
    },
    text: "Normativa",
    title: "Normativa",
  },
  {
    toView: {
      name: "Inicio",
      hash: "#Contacto",
    },
    text: "Contacto",
    title: "Contacto",
  },
];

export { NAVBAR_SECTIONS };
