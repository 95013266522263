<template>
  <div class="normativadependenciatwitter-comp">
    <p class="subtitulo-02">Ligas de Interés</p>

    <div class="area-normativas">
      <a class="normativa-item" v-for="(item, index) in cmp_NormativaListLimit" :key="index" :href="item.Link" :title="item.Titulo" target="_blank">
        <p class="texto">{{ mth_LimitTextLength(item.Titulo) }}</p>
      </a>
      <router-link class="normativa-mas" :to="{ name: 'Normativa', hash: '' }">
        <p class="texto text-center">Ver más...</p>
      </router-link>
    </div>

    <p class="subtitulo-02">Dependencias</p>

    <div class="area-dependencias">
      <router-link class="dependencia-item" v-for="(item, index) in cmp_SecretariaListLimit" :key="index" :to="{ name: 'Fracciones', hash: '', params: { secretaria: item.ID } }" :title="item.Nombre">
        <p class="texto">{{ mth_LimitTextLength(item.Nombre) }}</p>
      </router-link>
      <router-link class="dependencia-mas" :to="{ name: 'Dependencias', hash: '' }">
        <p class="texto text-center">Ver más...</p>
      </router-link>
    </div>

    <div class="twitter-lista-tweets-container">
      <a class="twitter-timeline" href="https://twitter.com/GobSanNicolas" data-dnt="true" data-lang="es" data-tweet-limit="5">Cargando tweets de GobSanNicolas...</a> 
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "normativadependenciatwitter-comp",
  props: [],
  mounted() {
    let twitterApi = document.createElement("script");
    twitterApi.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.head.appendChild(twitterApi);

    this.loadNormativaNSecretariaLists();
  },
  data() {
    return {
      SecretariaListLimit: 6,
      NormativaListLimit: 6,
      TextMaxLength: 50,
    };
  },
  methods: {
    mth_LimitTextLength(text) {
      if(text.length > 40){
        let tempMax = this.TextMaxLength;
        text = text.substring(0, tempMax);
        text = text + "...";
      }
      return text;
    },
    async loadNormativaNSecretariaLists() {
      // eslint-disable-next-line no-undef
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

      let urlSecretariaListLimit = `${this.cmp_URLSERVER}/SecretariaList?limit=${this.SecretariaListLimit}`;
      let urlNormativaListLimit = `${this.cmp_URLSERVER}/NormativaList?limit=${this.NormativaListLimit}`;
      
      let resSecretariaListLimit = [];
      let resNormativaListLimit = [];
      
      try {
      // eslint-disable-next-line no-undef
        const resultSecretariaListLimit = await axios.get(urlSecretariaListLimit, { headers: {} }); 
        resSecretariaListLimit = resultSecretariaListLimit.data;
      } catch (error) {
        console.dir(error);
      }
      this.$root.$store.dispatch("act_Save_SecretariaListLimit", resSecretariaListLimit);
      
      try {
      // eslint-disable-next-line no-undef
        const resultNormativaListLimit = await axios.get(urlNormativaListLimit, { headers: {} }); 
        resNormativaListLimit = resultNormativaListLimit.data;
      } catch (error) {
        console.dir(error);
      }
      this.$root.$store.dispatch("act_Save_NormativaListLimit", resNormativaListLimit);
    }
  },
  computed: {
    ...mapGetters({
      cmp_NormativaListLimit: "get_NormativaListLimit",
      cmp_SecretariaListLimit: "get_SecretariaListLimit",
      cmp_URLSERVER: "get_URLSERVER",
    }),
  },
};
</script>

<style scoped>
.normativadependenciatwitter-comp {
  display: grid;
  gap: var(--twitter-gap-separation);
  grid-auto-flow: row;
  min-width: 40ch;
}

.area-normativas, .area-dependencias {
  display: grid;
  gap: 0.2rem;
  grid-auto-flow: row;
}

.normativa-item, .dependencia-item, .normativa-mas, .dependencia-mas {
  text-decoration: none;
  color: var(--azul-03);
  font-size: 1rem;
  font-family: var(--font-BlinkerSemiBold);
  letter-spacing: 0.025ch;
}

.texto {
  padding: 0.2rem 0rem;
}

.normativa-mas, .dependencia-mas {
  margin-top: 0.5rem;
}

.twitter-lista-tweets-container {
  max-height: max(24vw, 47vh);
  overflow-y: auto;
  width: 100%;
}
</style>
