const SITEMAP_LINKS = [
  {
    toView: {
      name: "Inicio",
      hash: "",
    },
    text: "Home",
    title: "Inicio",
  },
  {
    toView: {
      name: "Dependencias",
      hash: "",
    },
    text: "Dependencia",
    title: "Dependencia",
  },
  {
    toView: {
      name: "Normativa",
      hash: "",
    },
    text: "Normativa",
    title: "Normativa",
  },
  {
    toView: {
      name: "AvisoPrivacidad",
      hash: "",
    },
    text: "Aviso de Privacidad",
    title: "Aviso de Privacidad",
  },
];

export { SITEMAP_LINKS };
