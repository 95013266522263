import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import module_navbar from "./modules/module_navbar";
import module_data from "./modules/module_data";

export default new Vuex.Store({
  modules: {
    module_navbar,
    module_data,
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
