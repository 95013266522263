<template>
  <div class="search-comp">
    <input class="search-input" type="search" id="search-input" title="Busqueda" v-on:search="mth_SetSearch" v-model="searchValue" autocomplete="off">
    <label for="search-input" class="search-label">
      <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
      <img class="img-search" :src="IMGS_GOB_SN.IconSearch" alt="SEARCH">
    </label>
  </div>
</template>

<script>
import { IMGS_GOB_SN } from "@/assets/js/imgs.js";

import { mapGetters } from "vuex";

export default {
  name: "search-comp",
  props: [],
  mounted() {
    this.IMGS_GOB_SN = IMGS_GOB_SN;
  },
  data() {
    return {
      IMGS_GOB_SN: {},
      searchValue: "",
      IsBusquedaActive: false,
    };
  },
  methods: {
    async mth_SetSearch() {
      if(this.searchValue.length <= 0) {
        console.dir(`searchValue.length: ${this.searchValue.length}`);
      }
      else {
        try {
          this.$root.$store.dispatch("act_Save_BusquedaValue", this.searchValue);

          //navigating
          this.$router.push({
              name: 'Busqueda', 
              hash: '#Busqueda'
          });

          this.IsBusquedaActive = true;
          this.$root.$store.dispatch("act_Save_IsBusquedaActive", this.IsBusquedaActive);
          // eslint-disable-next-line no-undef
          axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

          let urlBusqueda = `${this.cmp_URLSERVER}/Busqueda?search=${this.cmp_BusquedaValue}`;

          let resBusqueda = [];

          try {
          // eslint-disable-next-line no-undef
            const resultBusqueda = await axios.get(urlBusqueda, { headers: {} }); 
            resBusqueda = resultBusqueda.data;
          } catch (error) {
            console.dir(error);
          }

          this.$root.$store.dispatch("act_Save_Busqueda", resBusqueda);
        } catch (error) {
          console.dir(error);
        }
        
        this.IsBusquedaActive = false;
        this.$root.$store.dispatch("act_Save_IsBusquedaActive", this.IsBusquedaActive);
      }
    }
  },
  computed: {
    ...mapGetters({
      cmp_BusquedaValue: "get_BusquedaValue",
      cmp_URLSERVER: "get_URLSERVER",
    }),
  },
};
</script>

<style scoped>
.search-comp {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
}

.search-input {
  width: 40ch;
  max-width: 20vw;
  padding: 0.5rem;
}

.search-label i {
  font-size: 1.5rem;
}

.img-search {
  max-height: 1.5rem;
  object-fit: contain;
}
</style>
