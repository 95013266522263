<template>
  <div class="app-sn" id="app">
    <NavbarV2Comp class="navbar-v2" />
    <NavbarMobileComp class="navbar-mobile" />
    <router-view class="view" :key="$route.path"/>
    <FooterComp class="footer" />
    <div class="loading-screen" v-if="cmp_IsLoading">
      <div class="loading-container">
        <i class="fas fa-circle-notch fa-spin loading-icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NavbarV2Comp from "@/components/common/Navbar/NavbarV2Comp.vue";
import NavbarMobileComp from "@/components/common/Navbar/NavbarMobileComp.vue";
import FooterComp from "@/components/common/Footer/FooterComp.vue";

export default {
  components: {
    NavbarV2Comp,
    NavbarMobileComp,
    FooterComp,
  },
  name: "app-sn",
  props: [],
  created() {
  },
  mounted() {
    
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      cmp_IsLoading: "get_IsLoading",
    }),
  },
};
</script>

<style scoped>
.app-sn {
  position: relative;
  background-color: var(--white-01);
  /* color: var(--black-01); */
}

.view {
  min-height: var(--view-min-height);
  z-index: 1;
}

.navbar-v2 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.navbar-mobile {
  z-index: 2;
  display: none;
}

.loading-screen {
  z-index: 999999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.loading-container {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.loading-icon {
  font-size: 8vw;
}
</style>

<style src="@/assets/css/main.css"></style>
