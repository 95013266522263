<template>
  <div class="articulos-mobile-comp">
    <p class="titulo-02">Artículos</p>
    <div class="articulos-list">
      <router-link 
        class="articulo-item" 
        v-for="(item, index) in cmp_ArticuloListLimitReversed" 
        :key="index"
        :to="{ name: 'Articulo', params: { articulo: item.ID } }"
      >
        <span class="articulo-texto">{{ item.Nombre }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "articulos-mobile-comp",
  props: [],
  mounted() {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      cmp_ArticuloListLimit: "get_ArticuloListLimit",
    }),
    cmp_ArticuloListLimitReversed() {
      let items = [];
      try {
        items = this.cmp_ArticuloListLimit;
      } catch (error) {
        console.log(error);
      }
      return items.reverse();
    },
  },
};
</script>

<style scoped>
.articulos-mobile-comp {
  display: none;
  gap: 1rem;
  grid-auto-flow: row;
}

.articulos-list {
  display: grid;
  gap: 0.2rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.articulo-item {
  background-color: rgb(5, 106, 197);
  color: var(--white-01);
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 0.7rem;
  padding: 1.5rem 0rem;
}
</style>
