const GOB_LINKS = [
  {
    link: "https://www.gob.mx/",
    text: "Enlaces Federales",
    title: "",
  },
  {
    link: "https://www.nl.gob.mx/",
    text: "Enlaces Estatales",
    title: "",
  },
  {
    link: "https://sn.gob.mx/",
    text: "Enlaces Municipales",
    title: "",
  },
];

export { GOB_LINKS };
