export default {
  state: {
    URLSERVER: "/api/portaltransparencia",
    // URLSERVER: "https://localhost:44324/api/portaltransparencia",
    // URLSERVER: "http://idmdev.ddns.net:8136/api/portaltransparencia",
    // URLSERVER: "https://transparencia.sn.gob.mx/api/portaltransparencia",
    // URLSERVEROBRASPUBLICAS: "https://localhost:44349/",
    URLSERVEROBRASPUBLICAS: "http://idmdev.ddns.net:8104/",
    SecretariaView: {
      Nombre: "Secretaría de Obras Públicas y Desarrollo Urbano",
    },
    IsLoading: false,
    InfoRelevanteList: [
      {
        ID: 0,
        Descripcion: "Cargando...",
        Link: ""
      }
    ],
    Articulo: {
      ID: 0,
      Nombre: "Cargando...",
      Descripcion: "Cargando...",
      ListFraccion: []
    },
    ArticuloList: [],
    ArticuloListLimit: [],
    NormativaList: [
      {
        ID: 0,
        Titulo: "Cargando...",
        Link: ""
      }
    ],
    NormativaListLimit: [
      {
        ID: 0,
        Titulo: "Cargando...",
        Link: ""
      }
    ],
    Secretaria: {
      ID: 0,
      Nombre: "Cargando...",
    },
    SecretariaList: [
      {
        ID: 0,
        Nombre: "Cargando...",
      }
    ],
    SecretariaListLimit: [
      {
        ID: 0,
        Nombre: "Cargando...",
      }
    ],
    Contacto: {
      ID: 0,
      Titulo: "Cargando...",
      Contenido: "Cargando..."
    },
    Fraccion: {
      ID: 0,
      Nombre: "Cargando...",
    },
    FraccionList: [],
    FormatoList: [],
    CargaList: [],
    BusquedaValue: "Cargando...",
    IsBusquedaActive: false,
    Busqueda: [
      {
        Tipo: "Cargando",
        ListBusquedaItem: [
          {
            ID: 1,
            Titulo: "Cargando...",
            Contenido: "Cargando..."
          }
        ]
      },
    ],
    ObraList: [],
    Obra: {
      ID: 0,
      Nombre: "Cargando...",
      ListColonia: [],
    },
    ObraColoniaFormatoList: [],
    Colonia: {
      ID: 0,
      Nombre: "Cargando...",
    },
  },
  mutations: {
    mut_Save_URLSERVER(state, params) {
      state.URLSERVER = params;
    },
    mut_Save_URLSERVEROBRASPUBLICAS(state, params) {
      state.URLSERVEROBRASPUBLICAS = params;
    },
    mut_Save_SecretariaView(state, params) {
      state.SecretariaView = params;
    },
    mut_Save_IsLoading(state, params) {
      let temp = false;
      if(params) {
        temp = params;
      }
      state.IsLoading = temp;
    },
    mut_Save_InfoRelevanteList(state, params) {
      state.InfoRelevanteList = params;
    },
    mut_Save_ArticuloList(state, params) {
      state.ArticuloList = params;
    },
    mut_Save_ArticuloListLimit(state, params) {
      state.ArticuloListLimit = params;
    },
    mut_Save_NormativaList(state, params) {
      state.NormativaList = params;
    },
    mut_Save_NormativaListLimit(state, params) {
      state.NormativaListLimit = params;
    },
    mut_Save_Secretaria(state, params) {
      state.Secretaria = params;
    },
    mut_Save_SecretariaList(state, params) {
      state.SecretariaList = params;
    },
    mut_Save_SecretariaListLimit(state, params) {
      state.SecretariaListLimit = params;
    },
    mut_Save_Contacto(state, params) {
      state.Contacto = params;
    },
    mut_Save_Fraccion(state, params) {
      state.Fraccion = params;
    },
    mut_Save_FraccionList(state, params) {
      state.FraccionList = params;
    },
    mut_Save_FormatoList(state, params) {
      state.FormatoList = params;
    },
    mut_Save_Articulo(state, params) {
      state.Articulo = params;
    },
    mut_Save_CargaList(state, params) {
      state.CargaList = params;
    },
    mut_Save_IsBusquedaActive(state, params) {
      state.IsBusquedaActive = params;
    },
    mut_Save_BusquedaValue(state, params) {
      state.BusquedaValue = params;
    },
    mut_Save_Busqueda(state, params) {
      state.Busqueda = params;
    },
    mut_Save_ObraList(state, params) {
      state.ObraList = params;
    },
    mut_Save_Obra(state, params) {
      state.Obra = params;
    },
    mut_Save_ObraColoniaFormatoList(state, params) {
      state.ObraColoniaFormatoList = params;
    },
    mut_Save_Colonia(state, params) {
      state.Colonia = params;
    },
  },
  actions: {
    act_Save_URLSERVER(context, params) {
      context.commit("mut_Save_URLSERVER", params);
    },
    act_Save_URLSERVEROBRASPUBLICAS(context, params) {
      context.commit("mut_Save_URLSERVEROBRASPUBLICAS", params);
    },
    act_Save_SecretariaView(context, params) {
      context.commit("mut_Save_SecretariaView", params);
    },
    act_Save_IsLoading(context, params) {
      context.commit("mut_Save_IsLoading", params);
    },
    act_Save_InfoRelevanteList(context, params) {
      context.commit("mut_Save_InfoRelevanteList", params);
    },
    act_Save_ArticuloList(context, params) {
      context.commit("mut_Save_ArticuloList", params);
    },
    act_Save_ArticuloListLimit(context, params) {
      context.commit("mut_Save_ArticuloListLimit", params);
    },
    act_Save_NormativaList(context, params) {
      context.commit("mut_Save_NormativaList", params);
    },
    act_Save_NormativaListLimit(context, params) {
      context.commit("mut_Save_NormativaListLimit", params);
    },
    act_Save_Secretaria(context, params) {
      context.commit("mut_Save_Secretaria", params);
    },
    act_Save_SecretariaList(context, params) {
      context.commit("mut_Save_SecretariaList", params);
    },
    act_Save_SecretariaListLimit(context, params) {
      context.commit("mut_Save_SecretariaListLimit", params);
    },
    act_Save_Contacto(context, params) {
      context.commit("mut_Save_Contacto", params);
    },
    act_Save_Fraccion(context, params) {
      context.commit("mut_Save_Fraccion", params);
    },
    act_Save_FraccionList(context, params) {
      context.commit("mut_Save_FraccionList", params);
    },
    act_Save_FormatoList(context, params) {
      context.commit("mut_Save_FormatoList", params);
    },
    act_Save_Articulo(context, params) {
      context.commit("mut_Save_Articulo", params);
    },
    act_Save_CargaList(context, params) {
      context.commit("mut_Save_CargaList", params);
    },
    act_Save_IsBusquedaActive(context, params) {
      context.commit("mut_Save_IsBusquedaActive", params);
    },
    act_Save_BusquedaValue(context, params) {
      context.commit("mut_Save_BusquedaValue", params);
    },
    act_Save_Busqueda(context, params) {
      context.commit("mut_Save_Busqueda", params);
    },
    act_Save_ObraList(context, params) {
      context.commit("mut_Save_ObraList", params);
    },
    act_Save_Obra(context, params) {
      context.commit("mut_Save_Obra", params);
    },
    act_Save_ObraColoniaFormatoList(context, params) {
      context.commit("mut_Save_ObraColoniaFormatoList", params);
    },
    act_Save_Colonia(context, params) {
      context.commit("mut_Save_Colonia", params);
    },
  },
  getters: {
    get_URLSERVER(state) {
      return state.URLSERVER;
    },
    get_URLSERVEROBRASPUBLICAS(state) {
      return state.URLSERVEROBRASPUBLICAS;
    },
    get_SecretariaView(state) {
      return state.SecretariaView;
    },
    get_IsLoading(state) {
      return state.IsLoading;
    },
    get_InfoRelevanteList(state) {
      return state.InfoRelevanteList;
    },
    get_ArticuloList(state) {
      return state.ArticuloList;
    },
    get_ArticuloListLimit(state) {
      return state.ArticuloListLimit;
    },
    get_NormativaList(state) {
      return state.NormativaList;
    },
    get_NormativaListLimit(state) {
      return state.NormativaListLimit;
    },
    get_Secretaria(state) {
      return state.Secretaria;
    },
    get_SecretariaList(state) {
      return state.SecretariaList;
    },
    get_SecretariaListLimit(state) {
      return state.SecretariaListLimit;
    },
    get_Contacto(state) {
      return state.Contacto;
    },
    get_Fraccion(state) {
      return state.Fraccion;
    },
    get_FraccionList(state) {
      return state.FraccionList;
    },
    get_FormatoList(state) {
      return state.FormatoList;
    },
    get_Articulo(state) {
      return state.Articulo;
    },
    get_CargaList(state) {
      return state.CargaList;
    },
    get_IsBusquedaActive(state) {
      return state.IsBusquedaActive;
    },
    get_BusquedaValue(state) {
      return state.BusquedaValue;
    },
    get_Busqueda(state) {
      return state.Busqueda;
    },
    get_ObraList(state) {
      return state.ObraList;
    },
    get_Obra(state) {
      return state.Obra;
    },
    get_ObraColoniaFormatoList(state) {
      return state.ObraColoniaFormatoList;
    },
    get_Colonia(state) {
      return state.Colonia;
    },
  },
};
