<template>
  <div class="piearticuloschart-comp">
    <div class="pie-chart-container">
      <canvas
        class="pie-chart"
        id="pie-chart"
        width="70%"
        height="100%"
      ></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import "chartjs-plugin-labels";
//https://stackoverflow.com/a/26258671

import { mapGetters } from "vuex";

export default {
  name: "piearticuloschart-comp",
  props: ["propLabels", "propValues", "propFontSize", "propColors"],
  created() {
    window.mth_getArticuloListLimit = this.mth_getArticuloListLimit;
    window.mth_PieClicked = this.mth_PieClicked;
  },
  mounted() {
    setTimeout(() => {
      try {
        const ctx = document.getElementById("pie-chart");
        // eslint-disable-next-line no-unused-vars
        const pieChart = new Chart(ctx, this.ArticulosData);

        ctx.onclick = function(evt){
          try {
            var activePoints = pieChart.getElementsAtEvent(evt);

            if(activePoints[0]){
              // eslint-disable-next-line no-unused-vars
              var chartData = activePoints[0]['_chart'].config.data;
              var idx = activePoints[0]['_index'];
              
              // eslint-disable-next-line no-undef
              let list = mth_getArticuloListLimit();
              let res = list[idx];
              console.dir(res);
              // eslint-disable-next-line no-undef
              mth_PieClicked(res.ID);
            }
          } catch (error) {
            console.dir(error);
          }
        };
        
      } catch (error) {
        console.dir(error);
      }
    }, 10);
  },
  data() {
    return {
      ArticulosData: {
        type: "pie",
        data: {
          labels: this.propLabels,
          datasets: [
            {
              label: "Artículos",
              data: this.propValues,
              backgroundColor: this.propColors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          animation: {
            duration: 0
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          plugins: {
            labels: {
              render: "label",
              arc: true,
              position: "center",
              fontStyle: "bold",
              fontColor: "#fff",
              fontSize: this.propFontSize,
            },
          },
        },
      },
    };
  },
  methods: {
    mth_getArticuloListLimit() {
      return this.cmp_ArticuloListLimit;
    },
    mth_PieClicked(ID){
      //navigating
      this.$router.push({
          name: 'Articulo', 
          hash: '',
          params: { 
            articulo: ID,
          }
      });
    }
  },
  computed: {
    ...mapGetters({
      cmp_ArticuloListLimit: "get_ArticuloListLimit",
    }),
  },
};
</script>

<style scoped>
.piearticuloschart-comp {
  display: grid;
}

.pie-chart-container,
.pie-chart {
  width: 70%;
  height: 100%;
  aspect-ratio: 1 / 1;
  justify-self: center;
}
</style>
