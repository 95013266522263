<template>
  <div class="home-view">
    <HeaderComp />
    <p class="home-text view-pdd-header">
        <span>Es la herramienta sistematizada dónde se realiza la publicación de las
        Obligaciones de Transparencia de las Dependencias del Municipio de San
        Nicolás de los Garza, N.L.</span>
        <br />
        <span>De acuerdo a los artículos 95, 96 y 97 de la Ley de Transparencia y
        Acceso a la Información Pública del Estado de Nuevo León</span>
      </p>
    <div class="area-info-relevante view-pdd-header">
      <div class="area-top-info-relevante">
        <p class="titulo-02">Información más relevante</p>

        <div class="area-search-info-relevante">
          <label class="search-label" for="SearchInfoRelevante">Buscar información</label>
          <input class="search-input" type="search" id="SearchInfoRelevante" name="SearchInfoRelevante" placeholder="Escriba para buscar..." v-model="InfoListSearch" v-on:input="mth_InfoListFilter" autocomplete="off">
          <!-- <i class="search-icon fa-solid fa-magnifying-glass"></i> -->
          <img class="img-search" :src="IMGS_GOB_SN.IconSearch" alt="SEARCH">
        </div>
      </div>

      <div class="info-relevante-list">
        <p class="no-results" v-if="cmp_InfoRelevanteList.length === 0">Sin resultados...</p>
        <a
          v-for="(item, index) in cmp_InfoRelevanteList"
          :key="index"
          class="info-relevante-item"
          :style="mth_ResBackgroundColor(index)"
          :href="mth_InfoRelevanteLink(item.Link)"
          target="_blank"
        >
          <p class="texto">{{ item.Descripcion }}</p>
        </a>
      </div>
    </div>

    <ArticulosMobileComp class="view-pdd-header"/>

    <div class="area-articulos-n-comp view-pdd-header">
      <div class="area-articulos">
        <p class="titulo-02">Artículos</p>
        <PieArticulosChartComp
          v-if="IsShowPie"
          class="chart-comp"
          :propLabels="PieLabels"
          :propValues="PieValues"
          :propFontSize="cmp_ResFontSize"
          :propColors="cmp_ResBackgroundColors"
        />
      </div>

      <div class="area-comp">
        <NormativaDependenciaTwitter />
      </div>
    </div>

    <div class="area-contacto view-pdd-header" id="Contacto">
      <p class="titulo-02">Contacto</p>
      <p class="subtitulo-02 text-center subtitulo-contacto">
        {{ cmp_Contacto.Titulo }}
      </p>
      <div class="area-contacto-subarea">
        <img
          class="contacto-img"
          :src="IMGS_GOB_SN.ContactoMap"
          alt="CONTACTO MAPA"
        />
        <div class="contacto-html ql-snow ql-editor" v-html="cmp_Contacto.Contenido"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { IMGS_GOB_SN } from "@/assets/js/imgs.js";
import NormativaDependenciaTwitter from "@/components/common/Extras/NormativaDependenciaTwitterComp.vue";
import ArticulosMobileComp from "@/components/common/Extras/ArticulosMobileComp.vue";
import PieArticulosChartComp from "@/components/Views/Home/PieArticulosChartComp.vue";
import HeaderComp from "@/components/common/Extras/HeaderComp.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    NormativaDependenciaTwitter,
    ArticulosMobileComp,
    PieArticulosChartComp,
    HeaderComp,
  },
  name: "home-view",
  props: [],
  mounted() {
    this.IMGS_GOB_SN = IMGS_GOB_SN;

    this.mth_LoadAllData();
    this.mth_PieResults();
  },
  data() {
    return {
      timeoutInfoListSearch: null,
      InfoListSearch: "",
      InfoList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      ArticuloListLimit: 3,
      ItemsInRow: 3,
      IMGS_GOB_SN: {},
      IsShowPie: false,
      PieLabels: ["Artículo No. 95", "Artículo No. 96", "Artículo No. 97"],
      PieValues: [1, 1, 1],
      PieColors: [
        "rgb(255, 99, 132)",
        "rgb(54, 162, 235)",
        "rgb(255, 205, 86)",
        "rgb(255, 205, 86)",
      ],
      CONTACTO: {
        Titulo:
          "Centro Integral de Trasnparencia y Protección de Datos Personales",
        Contenido: "<p>Con<strong>tacto</strong></p>",
      },
    };
  },
  methods: {
    mth_InfoRelevanteLink(url) {
      // console.dir(url);
      // window.open(`${url}`,'_blank');
      if(!url.includes("http") || !url.includes("https")) {
        url = "//" + url;
      }
      return url;
    },
    mth_InfoListFilter() {
      try {
        clearTimeout(this.timeoutInfoListSearch);
        this.timeoutInfoListSearch = null;
        this.timeoutInfoListSearch = setTimeout(() => {
          this.mth_LoadAllData();
        }, 1500);
      } catch (error) {
        console.dir(error);
      }
    },
    async mth_LoadAllData() {
      // eslint-disable-next-line no-undef
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

      let urlInfoRelevante = `${this.cmp_URLSERVER}/InfoRelevante?search=${this.InfoListSearch}`;
      
      let urlContacto = `${this.cmp_URLSERVER}/Contacto`;

      let resInfoRelevanteList = [];
      let resContacto = {};

      try {
      // eslint-disable-next-line no-undef
        const resultInfoRelevante = await axios.get(urlInfoRelevante, { headers: {} }); 
        resInfoRelevanteList = resultInfoRelevante.data;
      } catch (error) {
        console.dir(error);
      }
      this.$root.$store.dispatch("act_Save_InfoRelevanteList", resInfoRelevanteList);
      
      try {
      // eslint-disable-next-line no-undef
        const resultContacto = await axios.get(urlContacto, { headers: {} }); 
        resContacto = resultContacto.data;
      } catch (error) {
        console.dir(error);
      }
      this.$root.$store.dispatch("act_Save_Contacto", resContacto);
    },
    mth_ResBackgroundColor(index) {
      let colors = [
        "rgb(3, 67, 123)",
        "rgb(3, 78, 161)",
        "rgb(5, 106, 197)",
        "rgb(0, 159, 226)",
        "rgb(0, 159, 226)"
      ];

      let numItem = index + 1;
      let itemsInRow = this.ItemsInRow;
      let whatRowIs = numItem / itemsInRow;
      let whatRowIsCeil = Math.ceil(whatRowIs);
      let totalColors = colors.length;
      
      let IsMoreThanColorsLength = whatRowIsCeil > totalColors;

      // console.dir(`numItem: ${numItem}, IsMoreThanColorsLength: ${IsMoreThanColorsLength}`);
      if(IsMoreThanColorsLength){
        let timesMore = whatRowIsCeil / totalColors;
        let timesMoreRound = Math.ceil(timesMore);
        let totalToRest = totalColors * timesMoreRound;
        // let tempToRest = whatRowIsCeil;
        whatRowIsCeil = whatRowIsCeil + totalColors - 1;
        whatRowIsCeil = whatRowIsCeil - totalToRest;
        whatRowIsCeil = whatRowIsCeil + 1;
        // console.dir(`timesMore: ${timesMore}:${timesMoreRound}, totalToRest: ${totalToRest}, tempToRest: ${tempToRest}, whatRowIsCeil: ${whatRowIsCeil}`);
      }
      let resColorIndex = whatRowIsCeil - 1;

      let res = colors[resColorIndex];
    
      return `background-color: ${res};`;
    },
    async mth_PieResults() {
      // eslint-disable-next-line no-undef
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

      let urlArticuloListLimit = `${this.cmp_URLSERVER}/ArticuloList?limit=${this.ArticuloListLimit}`;
      let resArticuloListLimit = [];

      try {
        // eslint-disable-next-line no-undef
        const resultArticuloListLimit = await axios.get(urlArticuloListLimit, { headers: {} }); 
        resArticuloListLimit = resultArticuloListLimit.data;
      } catch (error) {
        console.dir(error);
      }
      this.$root.$store.dispatch("act_Save_ArticuloListLimit", resArticuloListLimit);

      let articuloList = [...resArticuloListLimit];
      
      if(articuloList.length <= 0)
      {
        let tempCargando = [
          {
            ID: 1,
            Nombre: "Cargando...",
          },
          {
            ID: 2,
            Nombre: "Cargando...",
          }
        ];
        articuloList = tempCargando;
      }

      //load from api
      articuloList = articuloList.reverse();
      let resLabels = articuloList.map((item) => item.Nombre);
      let resValues = resLabels.map((item) => item.length / item.length);
      this.PieLabels = resLabels;
      this.PieValues = resValues;

      this.IsShowPie = true;
    },
  },
  computed: {
    ...mapGetters({
      cmp_InfoRelevanteList: "get_InfoRelevanteList",
      cmp_ArticuloList: "get_ArticuloList",
      cmp_ArticuloListLimit: "get_ArticuloListLimit",
      cmp_Contacto: "get_Contacto",
      cmp_URLSERVER: "get_URLSERVER",
    }),
    cmp_ResFontSize() {
      // let tempCount = this.PieLabels.length;
      // let tempRes = this.PieFontSize / tempCount;
      // let tempMultiply = this.PieFontSize / tempRes;
      // let result = tempRes * tempMultiply;
      // eslint-disable-next-line no-unused-vars
      // let resFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      // let resTemp = resFontSize * tempCount;
      // let resTemp2 = tempCount / resFontSize;
      // let resTemp3 = resTemp2 / resTemp;
      // let resTemp4 = resTemp3 * 10000;
      // let resTemp5 = resTemp / resTemp2;
      // let resTemp6 = resFontSize * .63;
      // console.dir(resTemp4, resTemp5, resTemp6);
      
      //Items: 3 > Fuente: 50
      //Items: 6 > Fuente: 10
      return 30;
    },
    cmp_ResBackgroundColors() {
      let colors = [
        "rgb(33, 99, 172)",
        "rgb(2, 78, 161)",
        "rgb(54, 129, 193)",

        "rgb(3, 67, 123)",
        "rgb(3, 78, 161)",
        "rgb(5, 106, 197)",
        "rgb(0, 159, 226)",
        "rgb(0, 159, 226)"
      ];

      let resColors = [];

      for (var i = 0; i < this.PieLabels.length; i++) {
        resColors.push(colors[i % colors.length]);  
      }
      return resColors;
    }
  },
};
</script>

<style scoped>
.home-view {
  display: grid;
  gap: var(--view-home-gap-separation);
  grid-auto-flow: row;

  /* 7.8vw L R */
}

.area-info-relevante {
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
}

.area-top-info-relevante {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
}

.area-search-info-relevante {
  display: grid;
  gap: 0.5rem;
  grid-template-areas: "Label" "Input";
  grid-template-columns: 1fr;
}

.search-label {
  grid-area: Label;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--black-01);
}

.search-input {
  grid-area: Input;
  padding: 0.2rem;
  min-width: 40ch;
}

.search-icon {
  grid-area: Icon;
  font-size: 1.5rem;
  color: var(--black-01);
  align-self: center;
  text-align: center;
}

.info-relevante-list {
  display: grid;
  column-gap: 3rem;
  row-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.info-relevante-list .info-relevante-item {
  padding: 1rem;
  text-decoration: none;
}

.info-relevante-list .info-relevante-item .texto {
  color: var(--white-01);
  font-family: var(--font-BlinkerRegular);
  letter-spacing: 0.05ch;
  font-size: 1rem;
}

.info-relevante-item:nth-child(n + 1):nth-child(-n + 6) {
  background-color: var(--azul-01);
}

.info-relevante-item:nth-child(n + 7):nth-child(-n + 12) {
  background-color: var(--azul-02);
}

.info-relevante-item:nth-child(n + 13):nth-child(-n + 18) {
  background-color: var(--azul-03);
}

.info-relevante-item:nth-child(n + 19):nth-child(-n + 24) {
  background-color: var(--azul-04);
}

.area-articulos-n-comp {
  display: grid;
  gap: var(--view-home-gap-separation);
  grid-template-columns: 1fr auto;
}

.area-articulos {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr;
}

.chart-comp {
  align-self: center;
}

.articulosChart {
  width: 90%;
  aspect-ratio: 1/1;
  align-self: center;
  justify-self: center;
  outline: 5px dotted black;
}

.area-contacto {
  display: grid;
  gap: var(--view-home-gap-separation-2);
  grid-auto-flow: row;
  padding-bottom: var(--view-home-gap-separation-2);
}

.subtitulo-contacto {
  align-self: center;
}

.area-contacto-subarea {
  display: grid;
  gap: var(--view-home-gap-separation-3);
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  align-items: center;
}

.area-contacto-subarea .contacto-img {
  max-width: 30vw;
  object-fit: contain;
  justify-self: center;
}

.area-contacto-subarea .contacto-html {
  color: initial;
}

.contacto-html.ql-snow.ql-editor {
  height: inherit !important;
}

.home-text {
  display: none;
  font-size: 1rem;
  font-family: var(--font-BlinkerSemiBold);
  color: var(--black-01);
  line-height: 1.5;
  margin-bottom: 1rem;
}

.img-search {
  display: none;
  object-fit: contain;
  height: 2.8rem;
  filter: invert(1);
}
</style>
