<template>
  <div class="footer-comp" :style="cmp_BG_Style">
    <div class="footer-top">
      <img
        class="logo-img"
        :src="IMGS_GOB_SN.LogoSNFullPortrait"
        alt="GOB SN LOGO"
      />

      <div class="footer-top-content">
        <div class="footer-top-area footer-top-content-left">
          <div class="footer-subarea">
            <p class="title">San Nicolás de los Garza</p>
            <p class="text">
              Búscanos en las redes sociales del municipio de San Nicolás de los
              Garza
            </p>
          </div>

          <div class="footer-social-links">
            <a
              v-for="(item, index) in SOCIAL_LINKS"
              :key="index"
              class="social-link"
              :href="item.link"
              target="_blank"
              :title="item.title"
            >
              <i class="fa-brands" :class="item.icon"></i>
            </a>
            <img class="social-img" :src="IMGS_GOB_SN.LogoGOBSN" alt="GOB SN" />
          </div>

          <div class="footer-gob-links">
            <a
              v-for="(item, index) in GOB_LINKS"
              :key="index"
              class="gob-link"
              :title="item.title"
              :href="item.link"
              target="_blank"
            >
              <p class="text">{{ item.text }}</p>
            </a>
          </div>
        </div>

        <div class="footer-top-area footer-top-content-center">
          <div class="footer-subarea">
            <p class="title">Mapa del Sitio</p>
            <router-link
              v-for="(item, index) in SITEMAP_LINKS"
              :key="index"
              class="sitemap-link"
              :to="item.toView"
              :title="item.title"
            >
              <p class="text">{{ item.text }}</p>
            </router-link>
          </div>
        </div>

        <div class="footer-top-area footer-top-content-right">
          <div class="footer-subarea">
            <p class="title">Contacto</p>
            <p class="text">{{ cmp_Contacto.Titulo }}</p>

            <p class="text text-contacto">
              <span>Edificio Jorge Treviño</span> <br />
              <span>A.V. Arturo de la Garza Villareal, San Nicolás de los GarzaN.L</span>
              <br />
              <span>Lic. Nora Ludivina Castillo Ordaz</span> <br />
              <span>8181581341</span> <br />
              <span>
                <a
                  class="mailto-link"
                  href="mailto:nora.castillo@sanicolas.gob.mx"
                >
                  <p class="text">nora.castillo@sanicolas.gob.mx</p>
                </a>
              </span>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <p class="text-dimakers">
      Gobierno del Estado de Nuevo León 2022. Powered by <a class="footer-dimakers" href="https://www.grupodimakers.com/" target="_blank">Grupo DIMAKERS ®</a>
    </p>
  </div>
</template>

<script>
import { IMGS_GOB_SN } from "@/assets/js/imgs.js";
import { GOB_LINKS } from "@/assets/js/gobLinks.js";
import { SOCIAL_LINKS } from "@/assets/js/socialLinks.js";
import { SITEMAP_LINKS } from "@/assets/js/siteMap.js";

import { mapGetters } from "vuex";

export default {
  name: "footer-comp",
  props: [],
  mounted() {
    this.IMGS_GOB_SN = IMGS_GOB_SN;
    this.GOB_LINKS = GOB_LINKS;
    this.SOCIAL_LINKS = SOCIAL_LINKS;
    this.SITEMAP_LINKS = SITEMAP_LINKS;
  },
  data() {
    return {
      IMGS_GOB_SN: {},
      GOB_LINKS: {},
      SOCIAL_LINKS: {},
      SITEMAP_LINKS: {},
      CONTACTO: {
        Titulo:
          "Centro Integral de Trasnparencia y Protección de Datos Personales.",
      },
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      cmp_Contacto: "get_Contacto",
    }),
    cmp_BG_Style() {
      return `background-image: url(${this.IMGS_GOB_SN.Footer});`;
    },
  },
};
</script>

<style scoped>
.footer-comp {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  display: grid;
  place-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  /* Para sacar la relacion de aspecto, son las medidas que tiene el archivo para el fondo del Footer */
  aspect-ratio: 1054.8/245.99;
  padding: var(--view-pdd-footer);
}

.footer-top {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  place-content: center;
}

.footer-top .logo-img {
  object-fit: contain;
  max-width: var(--footer-min-height);
  max-height: var(--footer-min-height);
  padding: calc(var(--footer-min-height) / 8);
  align-self: center;
}

.footer-comp .text-dimakers {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0rem 1rem 1rem 1rem;
  font-family: var(--font-BlinkerExtraLight);
  
  filter: drop-shadow(var(--footer-drop-shadow));
}

.footer-gob-links {
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: row;
}

.footer-gob-links .gob-link {
  text-decoration: none;
  color: var(--white-01);

  filter: drop-shadow(var(--footer-drop-shadow));
}

.footer-gob-links .gob-link .text {
  font-size: 1.1rem;
  font-family: var(--font-BlinkerThin);
}

.footer-top-content {
  display: grid;
  gap: 0.5rem;
  padding: 2.5rem;
  grid-template-columns: 1.5fr 0.8fr 1.2fr;
}

.footer-top-area {
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
  align-content: start;
}

.footer-subarea {
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: row;
  align-content: start;
}

.footer-subarea .title {
  position: relative;
  font-size: 1rem;
  font-family: var(--font-BlinkerRegular);
  padding: 0.4rem 0rem;
  
  filter: drop-shadow(var(--footer-drop-shadow));
}

.footer-subarea .title::before {
  position: absolute;
  content: "";
  width: 9ch;
  height: 0.2rem;
  background-color: var(--white-01);
  left: 0;
  bottom: 0;
}

.footer-subarea .text {
  font-family: var(--font-BlinkerExtraLight);
  
  filter: drop-shadow(var(--footer-drop-shadow));
}

.footer-social-links {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
}

.footer-social-links .social-img {
  max-height: 1.2rem;
  object-fit: contain;
}

.footer-social-links .social-link {
  text-decoration: none;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 5rem;
  display: grid;
  place-content: center;
  background-color: var(--white-01);
}

.footer-social-links .social-link i {
  font-size: 1rem;
  color: var(--azul-05);
}

.sitemap-link {
  text-decoration: none;
}

.sitemap-link .text {
  color: var(--white-01);
  font-family: var(--font-BlinkerThin);
}

.text-contacto {
  line-height: 1.2rem;
}

.mailto-link {
  text-decoration: none;
}

.mailto-link .text {
  color: var(--white-01);
  font-family: var(--font-BlinkerRegular);

  filter: none;
}

.footer-dimakers {
  text-decoration: none;
  color: var(--white-01);
}
</style>
