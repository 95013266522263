const IMGS_GOB_SN = {
  ArtAntorcha1: require("@/assets/images/sn/art_antorcha_1.png"),
  ArtLeon1: require("@/assets/images/sn/art_leon_1.png"),
  ArtLeon2: require("@/assets/images/sn/art_leon_2.png"),
  ArtLeon3: require("@/assets/images/sn/art_leon_3.png"),
  ArtLeon4: require("@/assets/images/sn/art_leon_4.png"),
  ArtSN1: require("@/assets/images/sn/art_sn_1.png"),
  Footer: require("@/assets/images/sn/footer.svg"),
  Header: require("@/assets/images/sn/header.svg"),
  IconFacebook: require("@/assets/images/sn/icon_facebook.png"),
  IconInstagram: require("@/assets/images/sn/icon_instagram.png"),
  IconTwitter: require("@/assets/images/sn/icon_twitter.png"),
  LogoGOBSN: require("@/assets/images/sn/logo_gob_sn.png"),
  LogoSNFullLandscape: require("@/assets/images/sn/logo_sn_full_landscape.png"),
  LogoSNFullPortrait: require("@/assets/images/sn/logo_sn_full_portrait.png"),
  LogoSN: require("@/assets/images/sn/snlogo.png"),
  Pastel: require("@/assets/images/sn/pastel.png"),
  ContactoMap: require("@/assets/images/sn/contacto_map.png"),
  IconSearch: require("@/assets/images/sn/icon_search.png"),
};

export {
  IMGS_GOB_SN
};
