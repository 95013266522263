<template>
  <div class="header-comp" :style="cmp_BG_Style">
    <div class="header-content-transparencia">
      <p class="title">Portal de transparencia</p>
      <p class="text">
        <span>Es la herramienta sistematizada dónde se realiza la publicación de las
        Obligaciones de Transparencia de las Dependencias del Municipio de San
        Nicolás de los Garza, N.L.</span>
        <br />
        <span>De acuerdo a los artículos 95, 96 y 97 de la Ley de Transparencia y
        Acceso a la Información Pública del Estado de Nuevo León</span>
      </p>
    </div>
  </div>
</template>

<script>
import { IMGS_GOB_SN } from "@/assets/js/imgs.js";

export default {
  name: "header-comp",
  props: [],
  mounted() {
    this.IMGS_GOB_SN = IMGS_GOB_SN;
  },
  data() {
    return {
      IMGS_GOB_SN: {},
    };
  },
  methods: {},
  computed: {
    cmp_BG_Style() {
      return `background-image: url(${this.IMGS_GOB_SN.Header});`;
    },
  },
};
</script>

<style scoped>
.header-comp {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white-01);
  display: grid;
  place-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  /* Para sacar la relacion de aspecto, son las medidas que tiene el archivo para el fondo del Header */
  aspect-ratio: 1054.27/402.55;
}

.header-content-transparencia {
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
  text-align: center;
  padding: 6vw 15vw 0vw 15vw;
}

.header-content-transparencia .title {
  font-size: 4.5rem;
  font-family: var(--font-BlinkerSemiBold);
}

.header-content-transparencia .text {
  font-size: 1.5rem;
  font-family: var(--font-BlinkerSemiBold);
}
</style>
