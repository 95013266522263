<template>
  <div class="navbarv2-comp" :class="{ 'on-scroll': !view.topOfPage}">
    <div class="sections">
      <router-link
        v-for="(item, index) in NAVBAR_SECTIONS"
        :key="index"
        class="section"
        :to="item.toView"
        :title="item.title"
      >
        <p class="text">{{ item.text }}</p>
      </router-link>
    </div>

    <router-link
      class="logo-link"
      :to="LOGO_LINK.toView"
      :title="LOGO_LINK.title"
    >
      <img
        class="logo-img"
        :src="IMGS_GOB_SN.LogoSNFullLandscape"
        alt="GOB SN LOGO"
      />
    </router-link>

    <div class="navbar-area">
      <SearchComp />
      <!-- <button class="accessibility-btn-sn" v-on:click="mth_OpenPlugin_UserWay">
        <i class="fa-solid fa-child-reaching"></i>
      </button> -->
    </div>
  </div>
</template>

<script>
import { IMGS_GOB_SN } from "@/assets/js/imgs.js";
import { NAVBAR_SECTIONS } from "@/assets/js/navbarSections.js";

import SearchComp from "@/components/common/Navbar/SearchComp.vue";

export default {
  components: {
    SearchComp,
  },
  name: "navbarv2-comp",
  props: [],
  beforeMount() {
    window.addEventListener('scroll', this.mth_handleScroll)
  },
  mounted() {
    this.IMGS_GOB_SN = IMGS_GOB_SN;
    this.NAVBAR_SECTIONS = NAVBAR_SECTIONS;
  },
  data() {
    return {
      IMGS_GOB_SN: {},
      NAVBAR_SECTIONS: {},
      LOGO_LINK: {
        toView: {
          name: "Inicio",
          hash: "",
        },
        text: "Home",
        title: "Inicio",
      },
      view: {
        topOfPage: true
      },
    };
  },
  methods: {
    mth_handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }
    },
    // mth_OpenPlugin_UserWay() {
    //   // eslint-disable-next-line no-undef
    //   UserWay.widgetOpen();
    // }
  },
  computed: {},
};
</script>

<style scoped>
.navbarv2-comp {
  display: grid;
  gap: var(--navbar-v2-gap-separation);
  grid-auto-flow: column;
  justify-self: center;
  align-items: center;
  padding: var(--view-pdd-header);
}

.on-scroll {
  background-color: var(--azul-01-transparente);
}

.navbar-area {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  justify-self: end;
}

.sections {
  display: grid;
  grid-auto-flow: column;
  justify-self: end;
  gap: 1rem;
}

.section {
  text-decoration: none;
  color: var(--white-01);
}

.section .text {
  padding: 1rem;
  font-size: 1.3rem;
  font-family: var(--font-BlinkerLight);
  filter: drop-shadow(var(--navbar-drop-shadow));
}

.logo-link {
  text-decoration: none;
  max-height: var(--navbar-min-height);
  display: grid;
  place-items: center;
}

.logo-link .logo-img {
  max-height: var(--navbar-min-height);
  object-fit: contain;
  padding: calc(var(--navbar-min-height) / 5);
}

.accessibility-btn-sn {
  border: 0;
  border-radius: 10rem;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.accessibility-btn-sn i {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  opacity: 0.6;
}
</style>
