import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/Inicio",
    name: "Inicio",
    // component: () => import(/* webpackChunkName: "chunk_homeview" */ "../views/HomeView.vue"),
    component: HomeView
  },
  {
    path: "/Dependencias",
    name: "Dependencias",
    component: () => import(/* webpackChunkName: "chunk_dependenciasview" */ "../views/DependenciasView.vue"),
  },
  {
    path: "/Normativa",
    name: "Normativa",
    component: () => import(/* webpackChunkName: "chunk_normativaview" */ "../views/NormativaView.vue"),
  },
  {
    path: "/ObrasDesarrollo",
    name: "ObrasDesarrollo",
    component: () => import(/* webpackChunkName: "chunk_obrasdesarrolloview" */ "../views/ObrasDesarrolloView.vue"),
  },
  {
    path: "/Anexos",
    name: "Anexos",
    component: () => import(/* webpackChunkName: "chunk_anexosview" */ "../views/AnexosView.vue"),
  },
  {
    path: "/PlanosZonificacion",
    name: "PlanosZonificacion",
    component: () => import(/* webpackChunkName: "chunk_planoszonificacionview" */ "../views/PlanosZonificacionView.vue"),
  },
  {
    path: "/MatricesSuelos",
    name: "MatricesSuelos",
    component: () => import(/* webpackChunkName: "chunk_matricessuelosview" */ "../views/MatricesSuelosView.vue"),
  },
  {
    path: "/RequerimientoEstacionamiento",
    name: "RequerimientoEstacionamiento",
    component: () => import(/* webpackChunkName: "chunk_requerimientoestacionamientoview" */ "../views/RequerimientoEstacionamientoView.vue"),
  },
  {
    path: "/LineamientosUrbanisticos",
    name: "LineamientosUrbanisticos",
    component: () => import(/* webpackChunkName: "chunk_lineamientosurbanisticosview" */ "../views/LineamientosUrbanisticosView.vue"),
  },
  {
    path: "/AvisoPrivacidad",
    name: "AvisoPrivacidad",
    component: () => import(/* webpackChunkName: "chunk_avisoprivacidadview" */ "../views/AvisoPrivacidadView.vue"),
  },
  {
    path: "/Fracciones/:secretaria",
    name: "Fracciones",
    component: () => import(/* webpackChunkName: "chunk_fraccionesview" */ "../views/FraccionesView.vue"),
  },
  {
    path: "/ObrasPublicas",
    name: "ObrasPublicas",
    component: () => import(/* webpackChunkName: "chunk_obraspublicasview" */ "../views/ObrasPublicasView.vue"),
  },
  {
    path: "/Colonias/:idobra",
    name: "Colonias",
    component: () => import(/* webpackChunkName: "chunk_coloniasview" */ "../views/ColoniasView.vue"),
  },
  {
    path: "/ObrasColoniasFormatos/:idobra/:idcolonia",
    name: "ObrasColoniasFormatos",
    component: () => import(/* webpackChunkName: "chunk_obracoloniaformatoview" */ "../views/ObraColoniaFormatoView.vue"),
  },
  {
    path: "/Fracciones/:secretaria/Formatos/:fraccion",
    name: "Formatos",
    component: () => import(/* webpackChunkName: "chunk_formatosview" */ "../views/FormatosView.vue"),
  },
  {
    path: "/Articulo/:articulo",
    name: "Articulo",
    component: () => import(/* webpackChunkName: "chunk_articuloview" */ "../views/ArticuloView.vue"),
  },
  {
    path: "/Busqueda",
    name: "Busqueda",
    component: () => import(/* webpackChunkName: "chunk_busquedaview" */ "../views/BusquedaView.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      if(to.path === "/Busqueda") {
        let resToAdd = 0;

        try {
          let resPortada = document.querySelector(".header-comp");
          let resNavbar = document.querySelector(".navbarv2-comp");
          let resYPortada = resPortada.scrollHeight;
          let resYNavbar = resNavbar.scrollHeight;
          resToAdd = resYPortada - resYNavbar;
        } catch (error) {
          console.dir(error);
        }

        return { x: 0, y: resToAdd, behavior: "smooth" };
      }

      return {
        selector: to.hash,
        behavior: "smooth",
        // , offset: { x: 0, y: 10 }
      };
    }

    let resIsNavbar = true;
    if(to.fullPath === "/AvisoPrivacidad"){
      resIsNavbar = false;
    }

    this.app.$root.$store.dispatch("act_Save_IsNavbar", resIsNavbar);

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

router.beforeResolve((to, from, next) => {
  try {
    router.app.$store.dispatch("act_Save_IsLoading", true);
  } catch (error) {
    console.dir(error);
  }
  setTimeout(function() {
    next();
  }, 10);
});

router.afterEach(() => {
  setTimeout(function() {
    try {
      router.app.$store.dispatch("act_Save_IsLoading", false);
    } catch (error) {
      console.dir(error);
    }
  }, 10);
});

export default router;
